import {isEqual} from "lodash";

const laravelIso8601Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}Z/;

export const snakeToCamel = (str) => {
  if (str.indexOf("_") === -1) return str; // Already camelCase
  return str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );
};

export const camelToTitle = str => str
  .replace(/([A-Z])/g, " $1")
  .replace(/^./, str => str.toUpperCase());


export const camelToSnake = (str) =>
  str
    .replace(/\w([A-Z])/g, (m) => m[0] + "_" + m[1])
    .toLowerCase();

const deepSnakeCaseKeys = (obj, exclude = []) => {
  if (typeof obj !== "object" || obj === null || obj instanceof File || obj instanceof Date || obj instanceof Blob) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepSnakeCaseKeys(item, exclude));
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (exclude.includes(key)) {
      return acc;
    } else {
      const snakeKey = camelToSnake(key);
      acc[snakeKey] = deepSnakeCaseKeys(value, exclude);
    }
    return acc;
  }, {});
};

export const deepCamelCaseKeys = (obj, exclude = []) => {
  if (typeof obj !== "object" || obj === null || obj instanceof File || obj instanceof Date || obj instanceof Blob) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCamelCaseKeys(item, exclude));
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (exclude.includes(key)) {
      return acc;
    } else {
      let camelKey = key;
      // This is a fix for an early bug by Dalton that is baked into the system
      if (typeof value !== "string" || !laravelIso8601Regex.test(value)) camelKey = snakeToCamel(key);
      acc[camelKey] = deepCamelCaseKeys(value, exclude);
    }
    return acc;
  }, {});
};

export const objectToSnakeCase = (object, exclude = []) => {
  return deepSnakeCaseKeys(object, exclude);
};

export const objectToCamelCase = (object, exclude = []) => {
  return deepCamelCaseKeys(object, exclude);
};

export const arrayToCamelCase = (array, exclude = []) => {
  if (!array) return [];
  return array.map((item) => deepCamelCaseKeys(item, exclude));
};

export const snakeCaseDifference = (object1, object2, exclude = []) => {
  const result = {};

  for (const [key, value] of Object.entries(object1)) {
    if (exclude.includes(key.toLowerCase())) continue;

    if (!isEqual(value, object2[key])) {
      result[camelToSnake(key)] = value;
    } else if (typeof value === "object" && value !== null) {
      const nestedDifference = snakeCaseDifference(value, object2[key], exclude);
      if (Object.keys(nestedDifference).length > 0) {
        result[camelToSnake(key)] = nestedDifference;
      }
    }
  }

  return result;
};

export const underscoreToTitleCase = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const stringifyWithoutFiles = (data) => {
  return JSON.stringify(data, (key, value) => {
    if (key === "file" || value instanceof File || value instanceof Blob || key.startsWith("file_") || key.endsWith("_file") || (typeof value === "string" && value.startsWith("data:") && value.length > 255)) return undefined;
    return value;
  });
};

export const serializeFormData = (data, methodSpoofType = "PUT") => {
  const formData = new FormData();
  formData.append("data", stringifyWithoutFiles(data));
  formData.append("_method", methodSpoofType); // Method Spoofing, required for Laravel, forms only support POST
  return formData;
};

/**
 * Syncs model has files using an existing form data object
 *
 * Expected structure of attachments:
 *
 * {
 *  order: number,
 *  file: File, (Optional)
 *  current_name: string,
 *  add_to_library: boolean,
 *  library_model_has_file_id: number, (Optional)
 *  model_has_file_id: number, (Required if existing)
 * }
 *
 * @param formData
 * @param tag
 * @param attachments
 */
export const syncModelHasFiles = (formData, tag, attachments) => {
  const attachmentsIsArray = Array.isArray(attachments);
  if (!attachmentsIsArray) attachments = [];

  const transformedAttachments = attachments
    .sort((a, b) => a.order - b.order)
    .map((attachment, order) => {
      if (attachment.file) {
        formData.append(`model_has_file_uploads_${tag}_${order}`, attachment.file);
      }

      return {
        model_has_file_id: attachment?.model_has_file_id,
        library_model_has_file_id: attachment?.library_model_has_file_id,
        current_name: attachment.current_name,
        order,
        add_to_library: attachment?.add_to_library || false,
      };
    });

  formData.append(`model_has_files_${tag}`, stringifyWithoutFiles(transformedAttachments));
};

export const deserializeFormData = (formData) => {
  return JSON.parse(formData.get("data"));
};

export const cleanObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
