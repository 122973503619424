<template>
  <div
    class="relative h-full min-h-screen w-full overflow-y-auto overflow-x-hidden bg-slate-50">
    <nav
      class="fixed top-0 right-0 left-0 z-50 flex h-14 items-center justify-center bg-black">
      <UtilityProfileInitials
        :avatar="avatar"
        :name="alt"
        size="sm"
        bg-class="bg-white bg-opacity-20"
        color-class="text-white"
      />
    </nav>
    <main
      id="main"
      class="relative pt-14 pb-32 min-h-fill-base-layout"
    >
      <slot/>
      <BaseFooter/>
    </main>
    <FloatingActionButtonContainer/>
  </div>
</template>

<script setup>
import BaseFooter from "../components/BaseFooter.vue";
import FloatingActionButtonContainer from "../components/FloatingActionButtonContainer.vue";
import UtilityProfileInitials from "../components/UtilityProfileInitials.vue";
import {provide, ref} from "vue";

const avatar = ref("");
const alt = ref("");

const setAvatar = (src, altText) => {
  avatar.value = src;
  alt.value = altText;
};

provide("setAvatar", setAvatar);
</script>
