<template>
  <TransitionFade>
    <div
      v-if="hasCurrentToasts"
      class="relative">

      <TransitionFade>
        <div
          class="w-12 h-12 min-w-12 min-h-12 max-h-12 max-w-12 relative">
          <button
            type="button"
            aria-label="High Priority Notifications"
            :aria-expanded="showPersistentNotifications ? 'true' : 'false'"
            class="bg-slate-900 focus:ring ring-bdBlue-200 ring-offset-2 hover:bg-black flex items-center justify-center text-white absolute inset-0 z-max intercom-transition cursor-pointer rounded-full shadow-lg transition-all focus:outline-none"
            @click="togglePersistentNotifications"
          >
            <TransitionFade>
              <Component
                :is="showPersistentNotifications ? ChevronDownIcon : BellAlertIcon"
                :key="showPersistentNotifications"
                class="w-6 h-6"
              />
            </TransitionFade>
          </button>
        </div>
      </TransitionFade>

      <Component
        v-model:show="showPersistentNotifications"
        v-model:current-toasts="currentToasts"
        :is="sonnerComponent"
        placement="absolute bottom-14 right-0"
        @dismiss="dismissToast"
      />

    </div>
  </TransitionFade>
</template>

<script setup>
import TransitionFade from "./TransitionFade.vue";
import {BellAlertIcon, ChevronDownIcon} from "@heroicons/vue/24/solid/index.js";
import {usePersistentNotifications} from "../composables/PersistentNotifications";
import {useRouter} from "vue-router";

const router = useRouter();

const {
  showPersistentNotifications,
  currentToasts,
  hasCurrentToasts,
  sonnerComponent,
  togglePersistentNotifications,
  dismissToast,
} = usePersistentNotifications(router);
</script>
