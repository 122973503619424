import * as Sentry from "@sentry/vue";

/*
 * Setup Sentry for error tracking
 *
 * @param {string} dsn
 * @param {import("vue").App} app
 * @param {import("vue-router").Router} router
 */
export default function setupSentry(dsn, app, router) {
  if (!dsn || !app || !router) return;

  Sentry.init({
    app,
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: "path",
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.01,
    tracePropagationTargets: ["businessdraft.com/api"],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.2,
    ignoreErrors: [
      "Non-Error promise rejection captured",
      "UnhandledRejection: Non-Error promise rejection captured with value: undefined",
    ],
  });
};
