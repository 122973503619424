import BaseApi from "./BaseApi";
import axios from "axios";

class NotificationApi extends BaseApi {
  constructor() {
    super("notifications");
  }
  persistentNotifications = () => axios.get("/api/persistent-notifications/");
  markFeatureAnnouncementSeen = (id) => axios.post(`/api/feature-announcements/${id}/seen`);
}

export default NotificationApi;
