import {createRouter, createWebHistory} from "vue-router";
import {
  checkUserHasRoles,
  doubleCheckIsLoggedIn,
  hasActiveCompany,
  hasActiveEnterprise,
  isDemoRequired
} from "../helpers/Redirects.js";
import LayoutAuth from "../layouts/LayoutAuth.vue";
import LayoutBase from "../layouts/LayoutBase.vue";
import LayoutBaseLight from "../layouts/LayoutBaseLight.vue";
import LayoutLogo from "../layouts/LayoutLogo.vue";
import LayoutCandidate from "../layouts/LayoutCandidate.vue";
import LayoutCompany from "../layouts/LayoutCompany.vue";
import LayoutEnterprise from "../layouts/LayoutEnterprise.vue";
import LayoutNone from "../layouts/LayoutNone.vue";
import {useAccountStore} from "../stores/AccountStore.js";
import {setItem} from "../helpers/LocalStorage.js";
import {COMPANY_ADMIN, MANAGER, RECRUITER} from "../constants/Roles.js";
import {ENV_DEMO} from "../constants/EnvironmentType";

const sharedRoutes = [
  {
    path: "/",
    name: "demo-index",
    component: () => import("../views/DemoIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Dashboard",
      documentTitle: "On-Demand Demo",
      useGradientBanner: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterIndex.vue"),
    meta: {
      layout: LayoutNone,
      documentTitle: "Register",
      blockOnDemo: true,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("../views/AuthLogin.vue"),
    meta: {
      layout: LayoutAuth,
      title: "Please log in to your account",
      documentTitle: "Login",
      blockOnDemo: true,
    },
  },
  {
    path: "/logout",
    name: "auth-logout",
    component: () => import("../views/AuthLogout.vue"),
    meta: {
      layout: LayoutNone,
      documentTitle: "Logout",
    },
  },
  {
    path: "/forgot-password",
    name: "auth-password-forgot",
    component: () => import("../views/AuthPasswordForgot.vue"),
    meta: {
      layout: LayoutAuth,
      title: "Forgot your password?",
      documentTitle: "Forgot Password",
      blockOnDemo: true,
    },
  },
  {
    path: "/reset-password",
    name: "auth-password-reset",
    component: () => import("../views/AuthPasswordReset.vue"),
    meta: {
      layout: LayoutAuth,
      title: "Reset your password",
      documentTitle: "Reset Password",
      blockOnDemo: true,
    },
  },
  {
    path: "/email/verified",
    name: "auth-verify-email-resend",
    component: () => import("../views/AuthVerifyEmail.vue"),
    meta: {
      layout: LayoutAuth,
      documentTitle: "Verify Email",
    },
  },
  {
    path: "/launch-pad",
    component: () => import("../views/AuthLaunchPad.vue"),
    name: "launch-pad",
    meta: {
      layout: LayoutAuth,
      title: "Launch Pad",
      requiresAuth: true,
      intercom: true,
    },
  },
  {
    path: "/two-factor-challenge",
    component: () => import("../views/AuthTwoFactorChallenge.vue"),
    name: "two-factor-challenge",
    meta: {
      layout: LayoutAuth,
      title: "Two Factor Challenge",
    }
  },
  {
    path: "/redirect",
    component: () => import("../views/LinkRedirect.vue"),
    name: "link-redirect",
    meta: {
      layout: LayoutNone,
      documentTitle: "Launch Pad",
    },
  },
  {
    path: "/unsubscribe",
    component: () => import("../views/UnsubscribeIndex.vue"),
    name: "unsubscribe-index",
    meta: {
      layout: LayoutAuth,
      documentTitle: "Unsubscribe",
    },
  },
  {
    path: "/reinstate-payment/:id",
    component: () => import("../views/ShowReinstatePayment.vue"),
    name: "show-reinstate-payment",
    props: true,
    meta: {
      layout: LayoutBase,
      requiresAuth: true,
      documentTitle: "Reinstate Payment",
    }
  },
  {
    path: "/deals/:slug",
    component: () => import("../views/BillingPlanLinkIndex.vue"),
    name: "billing-plan-link-index",
    props: true,
    meta: {
      layout: LayoutBase,
      documentTitle: "Join Business Draft",
      blockOnDemo: true,
    }
  },
  {
    path: "/confirm",
    component: () => import("../views/HeadlessUserStore.vue"),
    name: "headless-user-store",
    meta: {
      layout: LayoutLogo,
      documentTitle: "Confirm Your Info",
      intercom: true,
    },
  },
  {
    path: "/still-interested",
    component: () => import("../views/UnresponsiveApplicantShow.vue"),
    name: "unresponsive-applicant-show",
    meta: {
      layout: LayoutLogo,
      documentTitle: "Take Next Steps",
    },
  },
  {
    path: "/referrers/:id",
    component: () => import("../views/ReferrerShow.vue"),
    name: "referral-show",
    props: true,
    meta: {
      layout: LayoutBase,
      documentTitle: "Referral Portal",
      requiresAuth: true,
      title: "Referral Portal",
    },
  }
];

const candidateRoutes = [
  {
    path: "/create-account",
    name: "candidate-create-account",
    component: () => import("../views/CandidateAccountCreate.vue"),
    meta: {
      layout: LayoutBase,
      documentTitle: "Create Account",
      blockOnDemo: true,
      intercom: true,
    },
  },
  {
    path: "/dashboard",
    name: "candidate-dashboard-index",
    component: () => import("../views/CandidateDashboardIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "Dashboard",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Dashboard",
      intercom: true,
    },
  },
  {
    path: "/opportunities",
    name: "candidate-opportunity-index",
    component: () => import("../views/CandidateOpportunityIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "Opportunities",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Opportunities",
      intercom: true,
    },
  },
  {
    path: "/applications",
    name: "candidate-application-index",
    component: () => import("../views/CandidateApplicationIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "My Applications",
      requiresAuth: true,
      documentTitle: "Applications",
      intercom: true,
    },
  },
  {
    path: "/interviews",
    name: "candidate-interview-index",
    component: () => import("../views/CandidateInterviewIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "Interviews",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Interviews",
      intercom: true,
    },
  },
  {
    path: "/interviews/:uuid",
    name: "candidate-interview-show",
    component: () => import("../views/CandidateInterviewShow.vue"),
    props: true,
    meta: {
      layout: LayoutNone,
      title: "Interviews",
      useGradientBanner: true,
      requiresAuth: true,
      blockOnDemo: true,
    },
  },
  {
    path: "/schedule-interview/:uuid",
    name: "candidate-interview-schedule",
    component: () => import("../views/CandidateInterviewSchedule.vue"),
    props: true,
    meta: {
      layout: LayoutBaseLight,
      title: "Schedule Interview",
      useGradientBanner: true,
      requiresAuth: false,
    },
  },
  {
    path: "/interview-scheduled",
    name: "candidate-interview-scheduled",
    component: () => import("../views/CandidateInterviewScheduled.vue"),
    meta: {
      layout: LayoutNone,
      title: "Interview Scheduled",
      requiresAuth: false,
    },
  },
  {
    path: "/offers/:uuid",
    name: "candidate-offer-edit",
    component: () => import("../views/CandidateOfferLetterEdit.vue"),
    props: true,
    meta: {
      layout: LayoutNone,
      title: "Accept Offer Letter",
      useGradientBanner: true,
      requiresAuth: false,
      documentTitle: "Offer Letter",
    },
  },
  {
    path: "/messages",
    name: "candidate-message-index",
    component: () => import("../views/CandidateMessageIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      requiresAuth: true,
      documentTitle: "Messages",
      hideHeader: true,
    },
  },
  {
    path: "/notifications",
    name: "candidate-notification-index",
    component: () => import("../views/NotificationIndex.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "Notifications",
      requiresAuth: true,
      useGradientBanner: true,
      documentTitle: "Notifications",
      intercom: true,
    },
  },
  {
    path: "/notification-preferences",
    name: "candidate-notification-preferences",
    component: () => import("../views/NotificationPreferences.vue"),
    meta: {
      layout: LayoutCandidate,
      title: "Notification Settings",
      requiresAuth: true,
      useGradientBanner: true,
      documentTitle: "Notification Preferences",
      intercom: true,
    },
  },
  {
    path: "/account",
    component: () => import("../views/AuthAccount.vue"),
    name: "account",
    meta: {
      layout: LayoutCandidate,
      title: "Account",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Account",
      blockOnDemo: true,
      intercom: true,
    },
  },
  {
    path: "/profile",
    component: () => import("../views/CandidateProfileIndex.vue"),
    name: "profile-index",
    meta: {
      layout: LayoutCandidate,
      title: "My Profile",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Profile",
      intercom: true,
    },
  },
  {
    path: "/profile/edit",
    component: () => import("../views/CandidateProfileEdit.vue"),
    name: "profile-edit",
    meta: {
      layout: LayoutCandidate,
      title: "Profile Edit",
      useGradientBanner: true,
      requiresAuth: true,
      documentTitle: "Profile Edit",
    },
  },
  {
    path: "/profile/quick",
    component: () => import("../views/CandidateProfileQuick.vue"),
    name: "profile-quick",
    meta: {
      layout: LayoutNone,
      back: "/profile",
      requiresAuth: true,
      documentTitle: "Profile Quick",
    },
  },
  {
    path: "/finish-application/:uuid",
    component: () => import("../views/FinishApplication.vue"),
    name: "finish-application",
    props: true,
    meta: {
      layout: LayoutLogo,
      documentTitle: "Finish Your Application",
    }
  },
  {
    path: "/onboarding/:id",
    component: () => import("../views/OnboardingShow.vue"),
    name: "onboarding-show",
    props: true,
    meta: {
      layout: LayoutLogo,
      documentTitle: "Onboarding Steps",
    }
  }
];

const companyRoutes = [
  {
    path: "/create-company",
    name: "company-create-account",
    component: () => import("../views/CompanyAccountCreate.vue"),
    meta: {
      layout: LayoutBase,
      documentTitle: "Create Company",
      blockOnDemo: true,
    },
  },
  {
    path: "/activate-trial",
    name: "activate-trial",
    component: () => import("../views/CompanyAccountCreate.vue"),
    meta: {
      layout: LayoutBase,
      documentTitle: "Activate Your Free 14-Day Trial",
      blockOnDemo: true,
    },
  },
  {
    path: "/accept-invitation/:token",
    name: "team-member-create-account",
    component: () => import("../views/TeamMemberAccountCreate.vue"),
    props: true,
    meta: {
      layout: LayoutBase,
      documentTitle: "Accept Invitation",
      blockOnDemo: true,
    },
  },
  {
    path: "/add-location",
    name: "company-add-location",
    component: () => import("../views/UpgradeCompanyToEnterprise.vue"),
    meta: {
      layout: LayoutBase,
      documentTitle: "Add Location",
      blockOnDemo: true,
      requiresAuth: true,
    },
  },
  {
    path: "/company-dashboard",
    name: "company-dashboard-index",
    component: () => import("../views/CompanyDashboardIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Dashboard",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Dashboard",
      intercom: true,
    },
  },
  {
    path: "/company-opportunities",
    name: "company-opportunity-index",
    component: () => import("../views/CompanyOpportunityIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Opportunities",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Opportunities",
      intercom: true,
    },
  },
  {
    path: "/company-opportunities/create",
    name: "company-opportunity-create",
    component: () => import("../views/CompanyOpportunityCreate.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Create Opportunity",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Create Opportunity",
      intercom: true,
    },
  },
  {
    path: "/company-opportunities/:id",
    name: "company-opportunity-show",
    component: () => import("../views/CompanyOpportunityEdit.vue"),
    props: true,
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
    },
  },
  {
    path: "/company-opportunities/:id/edit",
    name: "company-opportunity-edit",
    component: () => import("../views/CompanyOpportunityEdit.vue"),
    props: true,
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
    },
  },
  {
    path: "/company-templates",
    name: "company-template-index",
    component: () => import("../views/CompanyTemplateIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Templates",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Templates",
    },
  },
  {
    path: "/company-templates/create",
    name: "company-template-create",
    component: () => import("../views/CompanyTemplateCreate.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Create Template",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Create Template",
    },
  },
  {
    path: "/company-templates/:id",
    name: "company-template-edit",
    component: () => import("../views/CompanyTemplateEdit.vue"),
    props: true,
    meta: {
      layout: LayoutCompany,
      title: "Edit Template",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Edit Template",
    },
  },
  {
    path: "/company-candidates",
    name: "company-candidate-index",
    component: () => import("../views/CompanyCandidateIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Candidates",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Candidates",
      intercom: true,
    },
  },
  {
    path: "/company-candidates/rolodex-contact/create",
    name: "company-rolodex-contact-create",
    component: () => import("../views/CompanyRolodexContactCreate.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Add Rolodex Contact",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Add Rolodex Contact",
      blockOnDemo: true,
    },
  },
  {
    path: "/company-interviews",
    name: "company-interview-index",
    component: () => import("../views/CompanyInterviewIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Interviews",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Interviews",
    },
  },
  {
    path: "/company-interviews/:uuid",
    name: "company-interview-show",
    component: () => import("../views/CompanyInterviewShow.vue"),
    props: true,
    meta: {
      layout: LayoutNone,
      title: "Interview",
      useGradientBanner: false,
      requiresAuth: true,
      requiresActiveCompany: true,
      blockOnDemo: true,
    },
  },
  {
    path: "/company-interviews/create",
    name: "company-interview-create",
    component: () => import("../views/CompanyInterviewCreate.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Schedule Interview",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Schedule Interview",
    },
  },
  {
    path: "/demo-interview",
    name: "demo-interview-index",
    component: () => import("../views/DemoInterviewIndex.vue"),
    meta: {
      layout: LayoutNone,
      title: "Demo Interview",
      documentTitle: "Demo Interview",
    },
  },
  {
    path: "/company-messages",
    name: "company-message-index",
    component: () => import("../views/CompanyMessageIndex.vue"),
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Messages",
      hideHeader: true,
    },
  },
  {
    path: "/company-notifications",
    name: "company-notification-index",
    component: () => import("../views/NotificationIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Notifications",
      requiresAuth: true,
      requiresActiveCompany: true,
      useGradientBanner: true,
      documentTitle: "Notifications",
      intercom: true,
    },
  },
  {
    path: "/company-notification-preferences",
    name: "company-settings-index",
    component: () => import("../views/NotificationPreferences.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Notification Preferences",
      requiresAuth: true,
      requiresActiveCompany: true,
      useGradientBanner: true,
      documentTitle: "Notification Preferences",
      intercom: true,
    },
  },
  {
    path: "/company-account",
    name: "company-account-index",
    component: () => import("../views/AuthAccount.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Account",
      requiresAuth: true,
      requiresActiveCompany: true,
      useGradientBanner: true,
      documentTitle: "Account",
      isCompany: true,
      blockOnDemo: true,
      intercom: true,
    },
  },
  {
    path: "/company-profile",
    component: () => import("../views/CompanyProfileIndex.vue"),
    name: "company-profile-index",
    meta: {
      layout: LayoutCompany,
      title: "Company Profile",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Company Profile",
      intercom: true,
    },
  },
  {
    path: "/company-profile/edit",
    component: () => import("../views/CompanyProfileEdit.vue"),
    name: "company-profile-edit",
    meta: {
      layout: LayoutCompany,
      title: "Company Profile",
      requiresAuth: true,
      requiresActiveCompany: true,
      roles: [COMPANY_ADMIN, MANAGER]
    },
  },
  {
    path: "/company-profile/quick",
    component: () => import("../views/CompanyProfileQuick.vue"),
    name: "company-profile-quick",
    meta: {
      layout: LayoutNone,
      back: "/company-profile",
      requiresAuth: true,
      documentTitle: "Company Profile",
      roles: [COMPANY_ADMIN, MANAGER]
    },
  },
  {
    path: "/team-members",
    component: () => import("../views/CompanyTeamMembersIndex.vue"),
    name: "team-members-index",
    meta: {
      layout: LayoutCompany,
      title: "Team Members",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Team Members",
      roles: [COMPANY_ADMIN, MANAGER, RECRUITER],
      intercom: true,
    },
  },
  {
    path: "/activities/:userId",
    component: () => import("../views/CompanyUserActivitiesIndex.vue"),
    name: "user-activities-index",
    props: true,
    meta: {
      layout: LayoutCompany,
      title: "User Activity Report",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "User Activity Report",
      roles: [COMPANY_ADMIN]
    },
  },
  {
    path: "/widgets",
    component: () => import("../views/CompanyWidgetIndex.vue"),
    name: "widgets-index",
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Add Widgets",
      useGradientBanner: true,
      title: "Add Widgets",
      blockOnDemo: true,
    },
  },
  {
    path: "/integrations",
    component: () => import("../views/IntegrationIndex.vue"),
    name: "integration-index",
    meta: {
      layout: LayoutCompany,
      title: "Integrations",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Integrations",
      blockOnDemo: true,
      intercom: true,
    },
  },
  {
    path: "/company-billing",
    name: "company-billing-index",
    component: () => import("../views/CompanyBillingIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Billing",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Billing",
      blockOnDemo: true,
    },
  },
  {
    path: "/company-billing/edit",
    name: "company-billing-edit",
    component: () => import("../views/CompanyBillingEdit.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Manage Plan & Payment",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Manage Plan & Payment",
    },
  },
  {
    path: "/company-invoices/:id",
    name: "company-invoice-show",
    component: () => import("../views/CompanyInvoiceShow.vue"),
    props: true,
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
      useGradientBanner: true,
      title: "View Invoice",
      documentTitle: "View Invoice",
    },
  },
  {
    path: "/user-imports",
    name: "user-import-index",
    component: () => import("../views/CompanyUserImportIndex.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Candidate Imports",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      blockOnDemo: true,
      documentTitle: "Candidate Imports",
    },
  },
  {
    path: "/user-imports/:id",
    name: "company-user-imports-show",
    component: () => import("../views/CompanyUserImportShow.vue"),
    props: true,
    meta: {
      layout: LayoutCompany,
      title: "Application Import",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Application Import",
    },
  },
  {
    path: "/user-imports/create",
    name: "company-user-imports-create",
    component: () => import("../views/CompanyUserImportCreate.vue"),
    meta: {
      layout: LayoutCompany,
      title: "Import Candidates",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Import Candidates",
    },
  },
  {
    path: "/career-pages/edit",
    name: "company-career-page-edit",
    component: () => import("../views/CompanyCareerPageEdit.vue"),
    meta: {
      layout: LayoutCompany,
      requiresAuth: true,
      requiresActiveCompany: true,
      documentTitle: "Career Page Builder",
      hideHeader: true,
    },
  }
];

const enterpriseRoutes = [
  {
    path: "/enterprise-groups",
    name: "enterprise-group-index",
    component: () => import("../views/EnterpriseGroupIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      blockOnDemo: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-groups/create",
    name: "enterprise-group-create",
    component: () => import("../views/EnterpriseGroupCreate.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-groups/:id",
    name: "enterprise-group-edit",
    component: () => import("../views/EnterpriseGroupEdit.vue"),
    props: true,
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-group-invites/:token",
    name: "enterprise-group-invite-show",
    component: () => import("../views/EnterpriseGroupInviteShow.vue"),
    props: true,
    meta: {
      layout: LayoutBase,
      title: "Invite",
      requiresAuth: false,
      documentTitle: "Invite",
    }
  },
  {
    path: "/enterprise-companies",
    name: "enterprise-company-index",
    component: () => import("../views/EnterpriseCompanyIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      blockOnDemo: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-companies/create",
    name: "enterprise-company-create",
    component: () => import("../views/EnterpriseCompanyCreate.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-companies/:id",
    name: "enterprise-company-edit",
    component: () => import("../views/EnterpriseCompanyEdit.vue"),
    props: true,
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-templates",
    name: "enterprise-template-index",
    component: () => import("../views/EnterpriseTemplateIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      title: "Enterprise Templates",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      documentTitle: "Enterprise Templates",
    },
  },
  {
    path: "/enterprise-billing-groups",
    name: "enterprise-billing-group-index",
    component: () => import("../views/EnterpriseBillingGroupIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-billing-groups/create",
    name: "enterprise-billing-group-create",
    component: () => import("../views/EnterpriseBillingGroupCreate.vue"),
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-billing-groups/:id",
    name: "enterprise-billing-group-edit",
    component: () => import("../views/EnterpriseBillingGroupEdit.vue"),
    props: true,
    meta: {
      layout: LayoutEnterprise,
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      componentHandlesTitle: true,
    },
  },
  {
    path: "/enterprise-access-manager",
    name: "enterprise-access-manager-index",
    component: () => import("../views/EnterpriseAccessManagerIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      title: "Access Manager",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      documentTitle: "Access Manager",
    },
  },
  {
    path: "/enterprise-access-manager/create",
    name: "enterprise-access-manager-create",
    component: () => import("../views/EnterpriseAccessManagerCreate.vue"),
    props: true,
    meta: {
      layout: LayoutEnterprise,
      title: "Invite New User",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      documentTitle: "Invite New User",
    },
  },
  {
    path: "/enterprise-access-manager/:id",
    name: "enterprise-access-manager-edit",
    component: () => import("../views/EnterpriseAccessManagerEdit.vue"),
    props: true,
    meta: {
      layout: LayoutEnterprise,
      title: "Manage Access",
      useGradientBanner: true,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      documentTitle: "Manage Access",
    },
  },
  {
    path: "/enterprise-account",
    name: "enterprise-account-index",
    component: () => import("../views/AuthAccount.vue"),
    meta: {
      layout: LayoutEnterprise,
      title: "Account",
      requiresAuth: true,
      requiresActiveEnterprise: true,
      useGradientBanner: true,
      documentTitle: "Account",
      blockOnDemo: true,
    },
  },
  {
    path: "/enterprise-invites/:token",
    name: "enterprise-invite-show",
    component: () => import("../views/EnterpriseInviteShow.vue"),
    props: true,
    meta: {
      layout: LayoutBase,
      title: "Enterprise Invite",
      requiresAuth: false,
      documentTitle: "Enterprise Invite",
    },
  },
  {
    path: "/enterprise-profile/edit",
    name: "enterprise-profile-edit",
    component: () => import("../views/EnterpriseProfileEdit.vue"),
    meta: {
      layout: LayoutEnterprise,
      title: "Enterprise Profile",
      requiresAuth: true,
      requiresActiveEnterprise: true,
      useGradientBanner: true,
      documentTitle: "Enterprise Profile",
    },
  },
  {
    path: "/enterprise-settings",
    name: "enterprise-self-index",
    component: () => import("../views/EnterpriseSelfIndex.vue"),
    meta: {
      layout: LayoutEnterprise,
      requiresAuth: true,
      requiresActiveEnterprise: true,
      useGradientBanner: true,
      componentHandlesTitle: true,
    },
  }
];


const integrationRoutes = [
  {
    path: "/calendar/create",
    name: "calendar-create",
    component: () => import("../views/TeamMemberCalendarCreate.vue"),
    meta: {
      layout: LayoutNone,
      documentTitle: "Link Calendar",
      blockOnDemo: true,
    }
  },
  {
    path: "/auth/:service/:status",
    component: () => import("../views/AuthIntegrationCallback.vue"),
    name: "auth-integration-status",
    meta: {
      layout: LayoutNone,
    },
    beforeEnter: (to, from, next) => {
      const {service, status} = to.params;
      const validServices = ["calendar", "indeed"];
      const validStatuses = ["connected", "not-connected"];

      if (validServices.includes(service) && validStatuses.includes(status)) {
        to.meta.integration = service.charAt(0).toUpperCase() + service.slice(1);
        to.meta.connected = status === "connected";
        next();
      } else {
        next({name: "error-404"});
      }
    }
  }
];

const errorRoutes = [
  {
    path: "/:pathMatch(.*)*",
    name: "error-404",
    component: () => import("../views/ErrorPage404.vue"),
    meta: {
      layout: LayoutNone,
      documentTitle: "Page Not Found",
    }
  }
];

const routes = [
  ...sharedRoutes,
  ...candidateRoutes,
  ...companyRoutes,
  ...enterpriseRoutes,
  ...integrationRoutes,
  ...errorRoutes,
];

let accountStore = null;
export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) return {el: to.hash, behavior: "smooth"};

    let main = document.getElementById("main");
    if (main) main.scrollTop = 0;
  },
});

router.beforeEach(async (to, from, next) => {
  if (window.onbeforeunload && !window.onbeforeunload(undefined)) return false;
  window.onbeforeunload = undefined;
  window.progressBar.start();
  if (to.meta?.requiresAuth && !await doubleCheckIsLoggedIn()) {
    setItem("targetUrl", to.fullPath, 1);
    if (import.meta.env.VITE_ENV === ENV_DEMO) return next({path: "/"});
    return next({path: "/login"});
  }
  if (to.meta?.requiresActiveCompany && !hasActiveCompany()) {
    setItem("targetUrl", to.fullPath, 1);
    return next({path: "/launch-pad"});
  }
  if (to.meta?.roles && !checkUserHasRoles(to.meta.roles)) {
    const pageName = to.meta.documentTitle || to.meta.title || "page";
    toast.warning(`You do not have permission to access the ${pageName}.`);
    if (from.name) return next({path: from.fullPath});
    return next({path: "/company-dashboard"});
  }
  if (to.meta?.requiresActiveEnterprise && !hasActiveEnterprise()) {
    setItem("targetUrl", to.fullPath, 1);
    return next({path: "/launch-pad"});
  }
  if (to.meta?.blockOnDemo && import.meta.env.VITE_ENV === ENV_DEMO) {
    if (from.name) {
      toast.warning("This feature is not available on the demo site.");
      return next({path: from.fullPath});
    }
    if (!await doubleCheckIsLoggedIn()) return next({path: "/"});
    return next({path: "/company-dashboard"});
  }
  if (isDemoRequired(from, to)) return next({path: "/company-dashboard"});
  if (to.meta?.documentTitle) document.title = `${to.meta.documentTitle} – Business Draft`;
  else if (!to.meta?.componentHandlesTitle) document.title = "Business Draft – Match. Connect. Hire.";

  return next();
});

router.afterEach((to) => {
  if (!accountStore) accountStore = useAccountStore();
  accountStore.routerAfterEach(to);
  window.progressBar.complete();
});
